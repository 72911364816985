import { CommonModule } from '@angular/common';
import { InjectionToken, NgModule } from '@angular/core';

import { IsFeatureDisabledDirective } from './directives/is-feature-disabled.directive';
import { IsFeatureEnabledDirective } from './directives/is-feature-enabled.directive';

export const LD_CLIENT_SIDE_ID = new InjectionToken<string>('LD_CLIENT_SIDE_ID');

@NgModule({
  imports: [CommonModule, IsFeatureEnabledDirective, IsFeatureDisabledDirective],
  exports: [IsFeatureEnabledDirective, IsFeatureDisabledDirective],
})
export class FeatureFlagModule {}
