import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Component({
  selector: 'di-grid-header-top',
  templateUrl: './grid-header-top.component.html',
  styleUrls: ['./grid-header-top.component.scss'],
  standalone: false,
})
export class GridHeaderTopComponent {
  @Output() autosizeColumns = new EventEmitter();
  @Output() getExcel = new EventEmitter();
  @Output() getCsv = new EventEmitter();
  @Output() changedSearchInput = new EventEmitter<string>();
  @Output() clearFilters = new EventEmitter();
  @Output() resetColumns = new EventEmitter();
  @Output() toggleFloatingFilter = new EventEmitter();
  isDropdownOpen = false;
  @Input() isFloatingFilterPresent: boolean;

  @HostListener('document:click', ['$event.target'])
  public onClick(target) {
    const settingsBtnEl = document.querySelector('#settings-btn');
    if (!settingsBtnEl?.contains(target)) {
      this.isDropdownOpen = false;
    }
  }

  onFilterButtonClick() {
    this.isFloatingFilterPresent = !this.isFloatingFilterPresent;
    this.toggleFloatingFilter.emit(this.isFloatingFilterPresent);
    const grid = document.querySelector('.ag-theme-visma');
    if (!this.isFloatingFilterPresent) {
      grid?.classList.add('visma-floating-filter-row-hidden');
    } else {
      grid?.classList.remove('visma-floating-filter-row-hidden');
    }
  }
}
