import { DecimalPipe } from '@angular/common';
import {
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  LOCALE_ID,
  Renderer2,
  forwardRef,
  inject,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';

const DEFAULT_NUMBER_FORMAT = '1.2-2';
const MAX_FRACTION_DIGITS_REGEXP = /^\d+\.\d+-(\d+)$/;
const ALLOWED_INPUT = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '-', ',', '.', 'Enter'];

type NumberInputValue = string | null;

@Directive({
  selector: 'input[diNumberInput]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberInputDirective),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => NumberInputDirective),
      multi: true,
    },
  ],
  standalone: false,
})
export class NumberInputDirective implements ControlValueAccessor, Validator {
  private elementRef = inject<ElementRef<HTMLInputElement>>(ElementRef);
  private renderer = inject(Renderer2);
  private appLocale = inject(LOCALE_ID);

  private _format = DEFAULT_NUMBER_FORMAT;
  private _locale?: string;
  private lastValueValid = false;
  private inputValue: NumberInputValue = null;
  private onControlValueChange?: (value: NumberInputValue) => void;
  private onControlValueTouched?: () => void;

  @HostBinding('disabled') disabled = false;
  @HostBinding('autocomplete') @Input() autocomplete = 'off';

  @Input('diNumberInput') set format(value: string) {
    if (!value) {
      this._format = DEFAULT_NUMBER_FORMAT;
    } else {
      if (!MAX_FRACTION_DIGITS_REGEXP.test(value)) {
        throw new Error(`${value} format value for [diNumberInput] is not valid`);
      }
      this._format = value;
    }

    const currValue = this.getInputValue();
    this.onInput(currValue);
    this.onChange(currValue);
  }

  get locale(): string {
    return this._locale || this.appLocale;
  }

  @Input()
  get value(): NumberInputValue {
    return this.inputValue;
  }

  set value(value: NumberInputValue) {
    if (value === null || (typeof value === 'string' && value.trim() !== '')) {
      this.lastValueValid = true;
      this.updateInput(value);
    } else {
      this.lastValueValid = false;
    }
  }

  @HostListener('change', ['$event.target.value'])
  onChange(value: string) {
    const { numberValue, invalid } = this.parseNumber(value);
    if (!invalid) {
      const trimmedValue = this.trimToMaxLength(numberValue);
      this.updateInput(trimmedValue);
      this.onControlValueChange?.(trimmedValue);
      this.inputValue = trimmedValue;
    }
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    const { numberValue, invalid } = this.parseNumber(value);
    if (invalid) {
      this.lastValueValid = false;
      this.onControlValueChange?.(null);
    } else {
      const trimmedValue = this.trimToMaxLength(numberValue);
      this.lastValueValid = true;
      this.onControlValueChange?.(trimmedValue ?? null);
      this.inputValue = trimmedValue;
    }
  }

  @HostListener('blur')
  onBlur() {
    this.onControlValueTouched?.();
  }

  @HostListener('keypress', ['$event'])
  keyPress(event: KeyboardEvent) {
    if (event.key && !event.metaKey && !ALLOWED_INPUT.includes(event.key)) {
      event.preventDefault();
      return;
    }

    if (event.key === ',' || event.key === '.') {
      event.preventDefault();

      const input = this.elementRef.nativeElement;
      const decimalSymbol = this.getDecimalSymbol(this.locale);

      if (input.setRangeText) {
        const start = input.selectionStart || 0;
        const end = input.selectionEnd || 0;

        input.setRangeText(decimalSymbol, start, end, 'end');
      }
      this.onInput(input.value);
    }
  }

  @HostListener('focus')
  onFocus() {
    setTimeout(() => this.elementRef.nativeElement.select());
  }

  writeValue(value: string | null): void {
    this.value = value;
    this.updateInput(value);
  }

  registerOnChange(fn: (value: NumberInputValue) => void): void {
    this.onControlValueChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onControlValueTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  validate(): ValidationErrors | null {
    return this.lastValueValid ||
      this.value === null ||
      this.value === undefined ||
      this.value === ''
      ? null
      : { number: true };
  }

  private updateInput(value: NumberInputValue): void {
    const displayValue = this.formatNumber(value);
    this.renderer.setProperty(this.elementRef.nativeElement, 'value', displayValue);
  }

  private getInputValue(): string {
    return this.elementRef.nativeElement.value;
  }

  private parseNumber(input: string): { numberValue?: string | null; invalid?: boolean } {
    if (!input) {
      return { numberValue: null };
    }

    let normalizedInput = input.replace(/\s/g, '');
    const decimalSeparator = new Intl.NumberFormat(this.locale).format(1.1).charAt(1);

    if (decimalSeparator === ',') {
      normalizedInput = normalizedInput.replace(',', '.');
    }

    const numberValue = Number(normalizedInput);

    if (isNaN(numberValue)) {
      return { invalid: true };
    }

    return { numberValue: normalizedInput };
  }

  private formatNumber(value: NumberInputValue): string {
    if (value === null || value === undefined || value === '') {
      return '';
    }

    const numberValue = Number(value);
    if (Number.isNaN(numberValue)) {
      return '';
    }

    if (Number.isInteger(numberValue)) {
      return numberValue.toString();
    }

    const formattedValue = new DecimalPipe(this.locale).transform(value, this._format);

    return formattedValue || '';
  }

  private getDecimalSymbol(locale: string): string {
    const formatter = new Intl.NumberFormat(locale);
    const parts = formatter.formatToParts(12345.6);
    const decimalPart = parts.find((part) => part.type === 'decimal');
    return decimalPart ? decimalPart.value : '.';
  }

  private trimToMaxLength(value: string): string {
    if (!value) {
      return '';
    }
    const match = this._format.match(MAX_FRACTION_DIGITS_REGEXP);
    const fractionDigits = match ? parseInt(match[1], 10) : 2;

    const [integerPart, decimalPart] = value.split('.');
    if (!decimalPart) {
      return integerPart;
    }

    const trimmedDecimalPart = decimalPart.substring(0, fractionDigits);
    return `${integerPart}.${trimmedDecimalPart}`;
  }
}
