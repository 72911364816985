import { Pipe, PipeTransform } from '@angular/core';

import { CompanyTagsModel } from '../../operations/bulk-tags/employee-for-tags.model';

@Pipe({
  name: 'tagsById',
  standalone: false,
})
export class TagsByIdPipe implements PipeTransform {
  transform(tagsIds: string[], tags: CompanyTagsModel[]): CompanyTagsModel[] {
    return tags?.filter((tag) => tagsIds?.includes(tag.id));
  }
}
