import { Component } from '@angular/core';
import { IStatusPanelAngularComp } from 'ag-grid-angular';
import { IStatusPanelParams } from 'ag-grid-community';

@Component({
  selector: 'di-grid-back-to-employees-btn',
  templateUrl: './grid-back-to-employees-btn.component.html',
  styleUrls: ['./grid-back-to-employees-btn.component.scss'],
  standalone: false,
})
export class GridBackToEmployeesBtnComponent implements IStatusPanelAngularComp {
  private params: IStatusPanelParams;

  agInit(params: IStatusPanelParams): void {
    this.params = params;
  }
}
