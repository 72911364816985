import {
  Component,
  ContentChild,
  DoCheck,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  inject,
} from '@angular/core';
import { RouterLinkActive } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'di-navbar-menu-item',
  templateUrl: './navbar-menu-item.component.html',
  styleUrls: ['./navbar-menu-item.component.scss'],
  standalone: false,
})
export class NavbarMenuItemComponent implements DoCheck {
  @Input() label = '';
  @Input() href?: string;
  @Input() iconClass?: string;
  @Input() isHidden? = false;
  @Input() isDisabled? = false;
  @Input() tooltipText?: TemplateRef<unknown>;

  // eslint-disable-next-line
  @Output() readonly click = new EventEmitter<MouseEvent>();

  /** @ignore */
  @ViewChild('content', { read: TemplateRef })
  readonly content!: TemplateRef<unknown>;

  /** @ignore */
  @ViewChild('secondary', { read: TemplateRef })
  readonly secondary!: TemplateRef<unknown>;

  /** @ignore */
  @ContentChild(TemplateRef) readonly labelTmp?: TemplateRef<unknown>;
  /** @ignore */
  public readonly elRef: ElementRef<HTMLElement> = inject(ElementRef);
  /** @ignore */
  private readonly routerLinkActive = inject(RouterLinkActive, {
    optional: true,
    self: true,
  });

  /** @ignore */
  private _routerLinkActive: boolean | null = null;
  /** @ignore */
  private _active = new BehaviorSubject(false);

  readonly isActive$ = this._active.asObservable();
  readonly isActive = () => this._active.getValue();

  @Input() set active(value: boolean) {
    this._active.next(value);
  }

  /** @ignore */
  ngDoCheck(): void {
    if (this.routerLinkActive && this.routerLinkActive.isActive !== this._routerLinkActive) {
      this._routerLinkActive = this.routerLinkActive.isActive;
      this._active.next(this._routerLinkActive);
    }
  }
}
