import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FeatureFlagModule } from '@feature-flag';
import { VismaUnifiedDesignModule } from '@vismaux/ngx-vud';

import { NumberInputModule } from '#shared/directives/number-input/number-input.module';

import { CollapsibleAreaComponent } from './components/collapsible-area/collapsible-area.component';
import { FilterCheckboxComponent } from './components/filter-checkbox/filter-checkbox.component';
import { FilterTotalsComponent } from './components/filter-totals/filter-totals.component';
import { GridBackToEmployeesBtnComponent } from './components/grid-back-to-employees-btn/grid-back-to-employees-btn.component';
import { GridCardDescComponent } from './components/grid-card/components/grid-card-desc.component';
import { GridCardTitleComponent } from './components/grid-card/components/grid-card-title.component';
import { GridCardComponent } from './components/grid-card/grid-card.component';
import { GridErrorRowRendererComponent } from './components/grid-error-row-renderer/grid-error-row-renderer.component';
import { GridHeaderTopComponent } from './components/grid-header-top/grid-header-top.component';
import { GridResultsStatusPanelComponent } from './components/grid-results-status-panel/grid-results-status-panel.component';
import { GridStatusColCellRendererComponent } from './components/grid-status-col-cell-renderer/grid-status-col-cell-renderer.component';
import { NavbarModule } from './components/navbar';
import { TagsErrorModalComponent } from './components/tags-error-modal/tags-error-modal.component';
import { TagLabelsMoreComponent } from './components/tags/tag-label-more/tag-labels-more.component';
import { UploadErrorsComponent } from './components/upload-errors/upload-errors.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { RequiredInputDirective } from './directives/required-input.directive';
import { WootricDirective } from './directives/wootric.directive';
import { LocalizedEnumPipe } from './localizers/localized-enum.pipe';
import { LocalizedInternationalIdPipe } from './localizers/localized-international-id.pipe';
import { LocalizedStringPipe } from './localizers/localized-string.pipe';
import { LocalizedTypeOfIdPipe } from './localizers/localized-type-of-id.pipe';
import { ActiveTimelineItemPipe } from './pipes/active-timeline-item.pipe';
import { ArrayIncludesPipe } from './pipes/array-includes.pipe';
import { TagsByIdPipe } from './pipes/tags-by-id.pipe';
import { ValueByIdPipe } from './pipes/value-by-id.pipe';

@NgModule({
  declarations: [
    UploadErrorsComponent,
    WootricDirective,
    ClickOutsideDirective,
    FilterCheckboxComponent,
    LocalizedStringPipe,
    FilterTotalsComponent,
    LocalizedEnumPipe,
    ValueByIdPipe,
    RequiredInputDirective,
    CollapsibleAreaComponent,
    ActiveTimelineItemPipe,
    TagsErrorModalComponent,
    ArrayIncludesPipe,
    TagsByIdPipe,
    TagLabelsMoreComponent,
    GridCardComponent,
    GridCardTitleComponent,
    GridCardDescComponent,
    LocalizedTypeOfIdPipe,
    LocalizedInternationalIdPipe,
    GridBackToEmployeesBtnComponent,
    GridResultsStatusPanelComponent,
    GridStatusColCellRendererComponent,
    GridErrorRowRendererComponent,
    GridHeaderTopComponent,
  ],
  imports: [
    CommonModule,
    VismaUnifiedDesignModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    FeatureFlagModule,
  ],
  exports: [
    UploadErrorsComponent,
    WootricDirective,
    ClickOutsideDirective,
    FilterCheckboxComponent,
    LocalizedStringPipe,
    FilterTotalsComponent,
    LocalizedEnumPipe,
    ValueByIdPipe,
    CollapsibleAreaComponent,
    ActiveTimelineItemPipe,
    TagsByIdPipe,
    TagLabelsMoreComponent,
    GridCardComponent,
    GridCardTitleComponent,
    GridCardDescComponent,
    NavbarModule,
    LocalizedTypeOfIdPipe,
    LocalizedInternationalIdPipe,
    GridHeaderTopComponent,
    RequiredInputDirective,
    NumberInputModule,
  ],
})
export class SharedModule {}
