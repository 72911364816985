import { Directive, ElementRef, OnInit } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';

@Directive({
  selector: '[diRequiredInput]',
  standalone: false,
})
export class RequiredInputDirective implements OnInit {
  constructor(
    private el: ElementRef,
    private control: NgControl,
  ) {}

  ngOnInit() {
    if (this.control) {
      const required = this.hasRequiredValidator(this.control.control);
      if (required) {
        this.el.nativeElement.parentElement.classList.add('required');
      }
    }
  }

  private hasRequiredValidator(control: AbstractControl): boolean {
    if (!control || !control.validator) {
      return false;
    }

    const validator = control.validator({} as AbstractControl);
    return validator && validator.required;
  }
}
