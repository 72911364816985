import { Pipe, PipeTransform } from '@angular/core';
import { LocalizedStringDto } from '@data-access/bulk-operations-api';
import { getTranslationFromLocalizedString } from '@em-helpers';

@Pipe({
  name: 'localizedString',
  standalone: false,
})
export class LocalizedStringPipe implements PipeTransform {
  transform(value: LocalizedStringDto): string {
    return getTranslationFromLocalizedString(value);
  }
}
