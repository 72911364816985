import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  inject,
} from '@angular/core';

import { NavbarService } from '../navbar.service';

@Component({
  selector: 'di-navbar-brand-option',
  templateUrl: './navbar-brand-option.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class NavbarBrandOptionComponent {
  private readonly navbarService = inject(NavbarService);

  @Input() active = false;
  @Input() label = '';
  @Input() href?: string;
  @Input() logoUrl?: string;
  // eslint-disable-next-line
  @Output() readonly click = new EventEmitter<MouseEvent>();

  /** @ignore */
  @ViewChild(TemplateRef, { static: true })
  readonly content!: TemplateRef<unknown>;

  /** @ignore */
  readonly isBurgerMenuBpMatched$ = this.navbarService.isBurgerMenuBpMatched$;
}
