import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { NavbarComponent } from '../navbar.component';

@Component({
  selector: 'di-navbar-brand',
  templateUrl: './navbar-brand.component.html',
  styleUrls: ['./navbar-brand.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class NavbarBrandComponent implements AfterContentInit, OnDestroy {
  public readonly navbar = inject(NavbarComponent, { host: true });
  private readonly cdRef = inject(ChangeDetectorRef);

  private destroyed$ = new Subject<void>();

  menuOpen = false;

  ngAfterContentInit(): void {
    // Removed non-null assertion as it is forbidden by lint
    this.navbar.brandOptions.changes
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.cdRef.markForCheck());
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }
}
